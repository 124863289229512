import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { serverUrl } from '../config/config.js';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient) { }
  isAuthenticated() {
    if(localStorage['loggedInAs'] !== null && localStorage['loggedInAs'] !== '') {
      return true
    }
    else return false;
  }
  checkLogin(loginCredentials) {
    let username = loginCredentials.loginUsername;
    let password = Md5.hashStr(loginCredentials.loginPassword.toString());
    let credentials = btoa(username+':'+password);
    return this.http.get(serverUrl+'portalLogin',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Basic ' + credentials
          }
        )
      }
    );
  }
}
