import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "../services/login.service";
import { Md5 } from "ts-md5/dist/md5";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private Auth: LoginService,
    private http: HttpClient,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private toastr: ToastrService
  ) {}

  loginButtonTitle: string = "Log In";
  isPressed: boolean = false;
  defloginUsername: string;
  defloginPassword: string;

  ngAfterViewInit() {
    if (
      localStorage["loggedInAs"] !== undefined &&
      localStorage["loggedInAs"] !== null
    )
      this.router.navigate(["AdminLayoutComponent"]);
  }
  ngOnInit() {
    this.titleService.setTitle("LIMS - Log In");
  }
  login(loginInfo) {
    this.isPressed = true;
    this.loginButtonTitle = "Logging In";
    this.Auth.checkLogin(loginInfo).subscribe(
      (data: any) => {
        if (data) {
          // if(data.userRole.toLowerCase() !== 'applicator') {
          if (data.userRole.toLowerCase() !== "applicator") {
            let username = loginInfo.loginUsername;
            let password = Md5.hashStr(loginInfo.loginPassword.toString());
            let credentials = btoa(username + ":" + password);
            localStorage.setItem(
              "loginCredentials",
              JSON.stringify(credentials)
            );
            this.router.navigate(["AdminLayoutComponent"]);
            localStorage.setItem("loggedInAs", JSON.stringify(data));
          } else {
            this.isPressed = false;
            this.loginButtonTitle = "Log In";
            this.defloginPassword = "";
            this.toastr.error(
              '<span data-notify="icon" class="nc-icon nc-alert-circle-i"></span><span data-notify="message">Sorry, you do not have permission to access the portal.</span>',
              "",
              {
                timeOut: 5000,
                enableHtml: true,
                closeButton: true,
                toastClass: "alert alert-danger alert-with-icon",
                positionClass: "toast-bottom-center",
              }
            );
          }
        }
      },
      (error) => {
        this.isPressed = false;
        this.loginButtonTitle = "Log In";
        this.defloginPassword = "";
        this.toastr.error(
          '<span data-notify="icon" class="nc-icon nc-alert-circle-i"></span><span data-notify="message">Username and password do not match. Please try again.</span>',
          "",
          {
            timeOut: 5000,
            enableHtml: true,
            closeButton: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-bottom-center",
          }
        );
      }
    );
  }
  forgot() {
    this.toastr.error(
      '<span data-notify="icon" class="nc-icon nc-alert-circle-i"></span><span data-notify="message">Please contact administrator to reset your account password.</span>',
      "",
      {
        timeOut: 5000,
        enableHtml: true,
        closeButton: true,
        toastClass: "alert alert-primary alert-with-icon",
        positionClass: "toast-bottom-center",
      }
    );
  }
}
