import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const Admin: RouteInfo[] = [
  { path: "/dash", title: "Dashboard", icon: "nc-trophy", class: "" },
  { path: "/organization", title: "Organizations", icon: "nc-bank", class: "" },
  { path: "/user", title: "Users", icon: "nc-single-02", class: "" },
  { path: "/client", title: "Clients", icon: "nc-badge", class: "" },
  { path: "/partner", title: "Partners", icon: "nc-tap-01", class: "" },
  {
    path: "/quotation",
    title: "Quotations",
    icon: "nc-credit-card",
    class: "",
  },
  { path: "/policy", title: "Policies", icon: "nc-paper", class: "" },
  {
    path: "/policy-assign",
    title: "Create Policy",
    icon: "nc-paper",
    class: "",
  },
  { path: "/claim", title: "Claims", icon: "nc-bookmark-2", class: "" },
  { path: "/vaccine", title: "Vaccine", icon: "nc-book-bookmark", class: "" },
  { path: "/inventory", title: "Inventory", icon: "nc-box", class: "" },
  { path: "/report", title: "Reports", icon: "nc-single-copy-04", class: "" },
];
export const PartnerOrManagement: RouteInfo[] = [
  { path: "/dash-partner", title: "Dashboard", icon: "nc-trophy", class: "" },
  { path: "/client", title: "Clients", icon: "nc-badge", class: "" },
  { path: "/claim", title: "Claims", icon: "nc-bookmark-2", class: "" },
  { path: "/vaccine", title: "Vaccine", icon: "nc-book-bookmark", class: "" },
];
export const Facilitator: RouteInfo[] = [
  { path: "/client", title: "Clients", icon: "nc-badge", class: "" },
];
export const Claim: RouteInfo[] = [
  {
    path: "/claims/approvals/waiting",
    title: "New Claims",
    icon: "nc-badge",
    class: "",
  },
  {
    path: "/claims/approvals/approved",
    title: "Approved Claims",
    icon: "nc-badge",
    class: "",
  },
  {
    path: "/claims/approvals/rejected",
    title: "Rejected Claims",
    icon: "nc-badge",
    class: "",
  },
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  constructor(private router: Router) {}
  public menuItems: any[];
  userRole: string;

  ngOnInit() {
    let currentRoute = this.router.url.toString();
    if (currentRoute.split("/")[1].toLowerCase() === "report")
      document
        .getElementById("sidebar-fake-link-ul")
        .classList.add("dis-block");
    let loggedInAs = JSON.parse(localStorage["loggedInAs"]);
    this.userRole = loggedInAs.userRole.trim().toLowerCase();
    if (this.userRole === "facilitator")
      this.menuItems = Facilitator.filter((menuItem) => menuItem);
    else if (this.userRole === "partner" || this.userRole === "management")
      this.menuItems = PartnerOrManagement.filter((menuItem) => menuItem);
    else if (this.userRole === "claim")
      this.menuItems = Claim.filter((menuItem) => menuItem);
    else this.menuItems = Admin.filter((menuItem) => menuItem);
    document
      .getElementById("sidebar-fake-link")
      .addEventListener("click", function () {
        document
          .getElementById("sidebar-fake-link-ul")
          .classList.toggle("dis-block");
      });
  }
}
