//Test
// export const serverUrl = 'http://159.65.10.205:8080/';

//Live
export const serverUrl = 'http://157.245.207.176:8080/';

// export const serverUrl = 'http://localhost:8080/';



// gdicMW1_pass
// 00Lockdown
// Ch@wkba2aar


// biznovelty@2!



// tpaAdmin_1234

// 0001@green-delta.com
// 20591



// Admin:
// Username: testuser
// Password: 1234

// Beneficiary:
// Username: 0015@green-delta.com
// Password: 1234

// 2400



// livestock
