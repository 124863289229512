import { Routes, CanActivate } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { GuardService } from './services/guard.service';

export const AppRoutes: Routes = [
  {
    path: '',
    // redirectTo: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    canActivate: [GuardService]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }
    ],
    canActivate: [GuardService]
  },
  {
    path: '**',
    redirectTo: 'dash'
  }
]
