import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(private router: Router) { }
  ngOnInit() {
    let loggedInAs = localStorage['loggedInAs'];
    // if(loggedInAs !== undefined && loggedInAs !== null) this.router.navigate(['AdminLayoutComponent']); 
    // else this.router.navigate(['./']);
    if(loggedInAs === undefined || loggedInAs === null) this.router.navigate(['./']);
  }
}
